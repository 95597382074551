module.exports = [{
      plugin: require('../plugins/gatsby-source-zms/gatsby-browser.js'),
      options: {"plugins":[],"baseURL":"http://id-onegas.framework.zehndev.com","models":["Content.Page","Staff.Person","Blog.Post","Blog.Topic","Events.Event","Masthead.Category","Masthead.Slide"],"pages":[{"type":"ContentPage","component":"/var/www/id.onegas.com/src/templates/ContentPageGet/index.js"},{"type":"BlogPost","pageSize":12,"pagePattern":["/articles/","/articles/page-$page/"],"listing":true,"component":"/var/www/id.onegas.com/src/templates/post-listing.js"},{"type":"BlogPost","component":"/var/www/id.onegas.com/src/templates/post-detail.js","path":"/post/*"},{"type":"EventsEvent","component":"/var/www/id.onegas.com/src/templates/event-detail.js","path":"/event/*"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-T37BMG5ZY0"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TPMD7NX","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"b3c6e2c14632db0b5c8eb855386aca16"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-100},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
